import {
  CheckOutlined,
  CloseOutlined,
  CopyOutlined,
  EditOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  Button,
  Form,
  InputNumber,
  Popconfirm,
  Space,
  Table,
  Tag,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import { IReplenishSuggestionItem } from '../../../@types/replenish-suggestion-item.interface';
import { IReplenishSuggestion } from '../../../@types/replenish-suggestion.interface';
import { SuggestionStatus } from '../../../@types/suggestion-status.enum';
import {
  getCloneReasonText,
  getStatusColor,
  getStatusText,
} from '../../../utils/statusUtils';
import './styles.scss';
interface ReplenishSuggestionExpandedRowProps {
  record: IReplenishSuggestion;
  cancelReplenishSuggestionItem: (rsi: IReplenishSuggestionItem) => void;
  onSaveReplenishSuggestionItem: (rsi: IReplenishSuggestionItem) => void;
  copyToClipboard: (text: string) => void;
}

const ReplenishSuggestionExpandedRow: React.FC<
  ReplenishSuggestionExpandedRowProps
> = ({
  record,
  cancelReplenishSuggestionItem,
  onSaveReplenishSuggestionItem,
  copyToClipboard,
}) => {
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();

  const cancelEditMode = () => {
    setEditingKey('');
  };
  const isEditingThisRow = (record: IReplenishSuggestionItem) =>
    record.id === editingKey;
  const onClickSaveReplenishSuggestionItem = (
    record: IReplenishSuggestionItem,
  ) => {
    form.validateFields().then((values) => {
      onSaveReplenishSuggestionItem({ ...record, ...values });
      setEditingKey('');
    });
  };

  const editReplenishSuggestionItem = (record: IReplenishSuggestionItem) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.id ?? '');
  };

  const expandColumns = [
    {
      title: 'Principal Product Name',
      dataIndex: 'principalProductName',
      key: 'principalProductName',
    },
    {
      title: 'Variant Name',
      dataIndex: 'variantName',
      key: 'variantName',
      render: (text: string, record: IReplenishSuggestionItem) => (
        <Typography.Text>
          {text}
          {record?.specifics && record.specifics.length > 0 ? (
            record.specifics.map((sp) => (
              <Typography.Paragraph key={sp.id} className="specifics-item">
                {sp.key + ': ' + sp.value}
              </Typography.Paragraph>
            ))
          ) : (
            <></>
          )}
        </Typography.Text>
      ),
    },
    {
      title: 'Pieces',
      dataIndex: 'pieces',
      key: 'pieces',
      render: (text: string, record: IReplenishSuggestionItem) => {
        return isEditingThisRow(record) ? (
          <Form.Item
            name="pieces"
            key={record.id}
            rules={[
              { required: true, message: 'Please input the number of pieces!' },
            ]}
          >
            <InputNumber min={1} value={record.pieces} />
          </Form.Item>
        ) : (
          <Typography.Text>{text}</Typography.Text>
        );
      },
    },
    {
      title: 'Base Unit',
      dataIndex: 'baseUnit',
      key: 'baseUnit',
      render: (text: string, record: IReplenishSuggestionItem) => (
        <Typography.Text>{`${record.baseUnitAmount} ${text}`}</Typography.Text>
      ),
    },
    {
      title: 'RS Product ID',
      dataIndex: 'externalProductId',
      key: 'externalProductId',
    },
    {
      title: 'RS Variant ID',
      dataIndex: 'externalVariantId',
      key: 'externalVariantId',
    },
    {
      title: 'Status',
      dataIndex: 'suggestionStatus',
      key: 'suggestionStatus',
      render: (_: any, rs: IReplenishSuggestionItem) => (
        <Tag
          color={getStatusColor(rs.suggestionStatus)}
          key={'suggestionStatus-' + rs.id}
        >
          {getStatusText(rs.suggestionStatus)}
        </Tag>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_: any, record: IReplenishSuggestionItem) => {
        return record.suggestionStatus === SuggestionStatus.PENDING ? (
          <>
            {isEditingThisRow(record) ? (
              <Space>
                <Popconfirm
                  title="Really save this item?"
                  onConfirm={() => onClickSaveReplenishSuggestionItem(record)}
                >
                  <Button
                    color="default"
                    variant="outlined"
                    style={{
                      color: '#4096ff',
                      borderColor: '#4096ff',
                    }}
                    icon={<CheckOutlined />}
                    title="Save"
                  />
                </Popconfirm>
                <Button
                  color="danger"
                  variant="outlined"
                  icon={<CloseOutlined />}
                  title="Abort"
                  onClick={cancelEditMode}
                />
              </Space>
            ) : (
              <Space>
                <Button
                  icon={<CopyOutlined />}
                  onClick={() => copyToClipboard(record.id)}
                  title="Copy Replenish Suggestion Item ID"
                />
                <Button
                  color="default"
                  variant="outlined"
                  icon={<EditOutlined />}
                  disabled={editingKey !== ''}
                  onClick={() => editReplenishSuggestionItem(record)}
                  style={{ color: '#4096ff', borderColor: '#4096ff' }}
                  title="Edit"
                ></Button>

                <Popconfirm
                  title="Really cancel this particular product of the Suggestion?"
                  onConfirm={() => cancelReplenishSuggestionItem(record)}
                >
                  <Button
                    danger
                    icon={<StopOutlined />}
                    disabled={editingKey !== ''}
                    title="Cancel Replenish Suggestion Product"
                  />
                </Popconfirm>
              </Space>
            )}
          </>
        ) : (
          <>
            {' '}
            <Button
              icon={<CopyOutlined />}
              onClick={() => copyToClipboard(record.id)}
              title="Copy Replenish Suggestion Item ID"
            />
          </>
        );
      },
    },
  ];

  return (
    <div className="expanded-row-wrapper">
      <Typography.Paragraph>
        Replenish Suggestion Items (Suggestion ID:{' '}
        <Typography.Text code>{record.id}</Typography.Text>)
      </Typography.Paragraph>
      <Typography.Paragraph>
        <Space>
          <Typography.Text>E-Mail Number: {record.emailNumber}</Typography.Text>
          {' | '}
          <Typography.Text>
            Template ID: {record.emailTemplateId}
          </Typography.Text>
          {record.clonedReason && (
            <>
              {' | '}
              <Typography.Text>
                Clone Reason: {getCloneReasonText(record.clonedReason)}
              </Typography.Text>
            </>
          )}
        </Space>
      </Typography.Paragraph>
      <Form form={form} initialValues={record}>
        <Table<IReplenishSuggestionItem>
          columns={expandColumns}
          dataSource={record.replenishSuggestionItems}
          pagination={false}
          rowKey={(record) => record.id}
          className="expanded-row"
        />
      </Form>
    </div>
  );
};

export default ReplenishSuggestionExpandedRow;
