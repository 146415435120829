import { ReplenishSuggestionCloneReason } from '../@types/replenish-suggestion-clone-reason.enum';
import { SuggestionStatus } from '../@types/suggestion-status.enum';

export const getStatusColor = (status: SuggestionStatus): string => {
  switch (status) {
    case SuggestionStatus.DELIVERED:
      return 'green';
    case SuggestionStatus.LED_TO_ORDER:
      return '#2c7e0a'; // Darker grey in same color shade as delivered
    case SuggestionStatus.PENDING:
      return 'orange';
    case SuggestionStatus.OBSOLETE_DUE_TO_NEW_ORDER:
      return '#a9a9a9'; // Grey
    case SuggestionStatus.CANCELLED_UNSENT_CLONE:
    case SuggestionStatus.ORDER_RECEIVED_INDEPENDENT_OF_REMINDER:
      return 'blue';
    case SuggestionStatus.CANCELLED_DUE_TO_OPT_OUT:
    case SuggestionStatus.CANCELLED_MANUALLY:
    case SuggestionStatus.CANCELLED_DUE_TO_UNAVAILABILITY:
    case SuggestionStatus.ERROR:
    case SuggestionStatus.ORDER_REFUNDED:
    case SuggestionStatus.ORDER_CANCELLED:
      return 'red';
    default:
      return 'red';
  }
};

export const getStatusText = (status: SuggestionStatus): string => {
  switch (status) {
    case SuggestionStatus.DELIVERED:
      return 'Delivered'.toUpperCase();
    case SuggestionStatus.LED_TO_ORDER:
      return 'Led to new order'.toUpperCase();
    case SuggestionStatus.PENDING:
      return 'Pending'.toUpperCase();
    case SuggestionStatus.OBSOLETE_DUE_TO_NEW_ORDER:
      return 'Obsolete due to new order'.toUpperCase();
    case SuggestionStatus.CANCELLED_UNSENT_CLONE:
      return 'Cancelled unsent clone'.toUpperCase();
    case SuggestionStatus.ORDER_RECEIVED_INDEPENDENT_OF_REMINDER:
      return 'Order received independent of reminder'.toUpperCase();
    case SuggestionStatus.CANCELLED_DUE_TO_OPT_OUT:
      return 'Cancelled due to opt-out'.toUpperCase();
    case SuggestionStatus.CANCELLED_MANUALLY:
      return 'Cancelled manually'.toUpperCase();
    case SuggestionStatus.ERROR:
      return 'Error'.toUpperCase();
    case SuggestionStatus.ORDER_REFUNDED:
      return 'Order refunded'.toUpperCase();
    case SuggestionStatus.ORDER_CANCELLED:
      return 'Order cancelled'.toUpperCase();
    case SuggestionStatus.CANCELLED_DUE_TO_UNAVAILABILITY:
      return 'Cancelled due to product unavailability'.toUpperCase();
    default:
      return 'unknown'.toUpperCase();
  }
};

export const getCloneReasonText = (
  status: ReplenishSuggestionCloneReason,
): string => {
  switch (status) {
    case ReplenishSuggestionCloneReason.EMAIL_BUTTON_REMIND_LATER_21D:
      return 'Email button remind later 21 days clicked'.toUpperCase();
    case ReplenishSuggestionCloneReason.EMAIL_BUTTON_REMIND_LATER_7D:
      return 'Email button remind later 7 days clicked'.toUpperCase();
    case ReplenishSuggestionCloneReason.FOLLOW_UP_EMAIL_7D:
      return 'Follow up email after 7 days'.toUpperCase();
    default:
      return 'unknown'.toUpperCase();
  }
};
